import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import Hero from "../components/hero"
import SEO from "../components/seo"
import Navbar from "../components/Navbar/Navbar"
import "../styles/subteams.css"
import StructuresImage from "../images/WIP_image.png"

const Wrapper = styled.div`
    display: grid;
    grid-auto-rows: minmax(0px, auto);
    grid-gap: 1em;
    color: #F5F5F7;
    font-family: 'Roboto';
    position: relative;
    text-align: left;
    top: 150px;
    margin-left: 100px
`
const StructuresSubteamPage = () => (
  <Layout>
    <SEO title="Structures Subteam" />
    <Navbar />
    <Wrapper>
        <h1> STRUCTURES </h1>
        <p className="tiger-team-description">Guidance, Navigation, and Control (GNC)</p>
        <table className="subteam-description"><tr>
        <td><img src={StructuresImage} alt="Structures Team" className="subteam-image"/></td>
        <td>The GNC team focuses on the mechanics of our control systems. Currently, the team is modeling a variety of sensor outputs and developing active control system designs.</td>
        </tr></table>
        <p className="tiger-team-description">Airframe</p>
        <table className="subteam-description"><tr>
        <td><img src={StructuresImage} alt="Structures Team" className="subteam-image"/></td>
        <td>The airframe team is in charge of design, manufacturing, and testing of the airframe. This team is currently running buckling simulations on a variety of airframe designs. These simulations are then confirmed with hand calculations for buckling with respect to pressures, temperature, and loading conditions.</td>
        </tr></table>
        <p className="tiger-team-description">Dynamic Modeling</p>
        <table className="subteam-description"><tr>
        <td><img src={StructuresImage} alt="Structures Team" className="subteam-image"/></td>
        <td>The dynamic modeling team develops mathematical/programmatic models to simulate the rocket's performance and flight path. Through our models, we are able to derive design parameters for the rocket, validate designs from the other subteams, and design control systems for the rocket.</td>
        </tr></table>
        <p className="tiger-team-description">Nosecone</p>
        <table className="subteam-description"><tr>
        <td><img src={StructuresImage} alt="Structures Team" className="subteam-image"/></td>
        <td>The nosecone team is designing the shape, material, and manufacturing processes of the nosecone through aerodynamic models, heating models, and structural loading models.</td>
        </tr></table>
        <p className="tiger-team-description">Test Stand</p>
        <table className="subteam-description"><tr>
        <td><img src={StructuresImage} alt="Structures Team" className="subteam-image"/></td>
        <td>The test stand team is in charge of all modifications and design of the structural components of the team's Test Stand. The team is currently manufacturing flooring, fixing fit/finish concerns between our blast plate and engine mount, and developing designs an interface between load cells in series with our engine.</td>
        </tr></table>
        <br />
    </Wrapper>
  </Layout>
)

export default StructuresSubteamPage
